<template>
  <div class="app-container excel">
    <el-tabs type="border-card" v-model="tabIndex">
      <el-tab-pane label="食品生产">
        <el-table
          :data="list1"
          v-loading="listLoading1"
          fit
          border
          style="width: 100%"
          :row-class-name="getRowClass"
          @expand-change="expandChange"
        >
          <el-table-column
            label="全省食品生产经营主体及第三方冷贮服务提供者冷库信息统计表"
            align="center"
          >
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-table :data="props.row.areaData" border fit v-loading="innerListLoading">
                  <el-table-column prop="region_name" align="center">
                  </el-table-column>
                  <el-table-column label="冷库总数量" prop="count" align="center">
                  </el-table-column>
                  <el-table-column label="冷库总面积（㎡）" prop="warehouse_area" align="center">
                  </el-table-column>
                  <el-table-column label="冷库总容积（m³）" prop="warehouse_volume" align="center">
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column prop="region_name" align="center">
            </el-table-column>
            <el-table-column label="冷库总数量" prop="count" align="center">
            </el-table-column>
            <el-table-column label="冷库总面积（㎡）" prop="warehouse_area" align="center">
            </el-table-column>
            <el-table-column label="冷库总容积（m³）" prop="warehouse_volume" align="center">
            </el-table-column>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="食品流通">
        <el-table
          :data="list2"
          v-loading="listLoading2"
          fit
          border
          style="width: 100%"
          :row-class-name="getRowClass"
          @expand-change="expandChange"
        >
          <el-table-column
            label="全省食品生产经营主体及第三方冷贮服务提供者冷库信息统计表"
            align="center"
          >
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-table :data="props.row.areaData" border fit v-loading="innerListLoading">
                  <el-table-column prop="region_name" align="center">
                  </el-table-column>
                  <el-table-column label="冷库总数量" prop="count" align="center">
                  </el-table-column>
                  <el-table-column label="冷库总面积（㎡）" prop="warehouse_area" align="center">
                  </el-table-column>
                  <el-table-column label="冷库总容积（m³）" prop="warehouse_volume" align="center">
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column prop="region_name" align="center">
            </el-table-column>
            <el-table-column label="冷库总数量" prop="count" align="center">
            </el-table-column>
            <el-table-column label="冷库总面积（㎡）" prop="warehouse_area" align="center">
            </el-table-column>
            <el-table-column label="冷库总容积（m³）" prop="warehouse_volume" align="center">
            </el-table-column>
          </el-table-column> </el-table
      ></el-tab-pane>
      <el-tab-pane label="餐饮服务提供者">
        <el-table
          :data="list3"
          v-loading="listLoading3"
          fit
          border
          style="width: 100%"
          :row-class-name="getRowClass"
          @expand-change="expandChange"
        >
          <el-table-column
            label="全省食品生产经营主体及第三方冷贮服务提供者冷库信息统计表"
            align="center"
          >
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-table :data="props.row.areaData" border fit v-loading="innerListLoading">
                  <el-table-column prop="region_name" align="center">
                  </el-table-column>
                  <el-table-column label="冷库总数量" prop="count" align="center">
                  </el-table-column>
                  <el-table-column label="冷库总面积（㎡）" prop="warehouse_area" align="center">
                  </el-table-column>
                  <el-table-column label="冷库总容积（m³）" prop="warehouse_volume" align="center">
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column prop="region_name" align="center">
            </el-table-column>
            <el-table-column label="冷库总数量" prop="count" align="center">
            </el-table-column>
            <el-table-column label="冷库总面积（㎡）" prop="warehouse_area" align="center">
            </el-table-column>
            <el-table-column label="冷库总容积（m³）" prop="warehouse_volume" align="center">
            </el-table-column>
          </el-table-column> </el-table
      ></el-tab-pane>
      <el-tab-pane label="农产品生产企业">
        <el-table
          :data="list13"
          v-loading="listLoading4"
          fit
          border
          style="width: 100%"
          :row-class-name="getRowClass"
          @expand-change="expandChange"
        >
          <el-table-column
            label="全省食品生产经营主体及第三方冷贮服务提供者冷库信息统计表"
            align="center"
          >
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-table :data="props.row.areaData" border fit v-loading="innerListLoading">
                  <el-table-column prop="region_name" align="center">
                  </el-table-column>
                  <el-table-column label="冷库总数量" prop="count" align="center">
                  </el-table-column>
                  <el-table-column label="冷库总面积（㎡）" prop="warehouse_area" align="center">
                  </el-table-column>
                  <el-table-column label="冷库总容积（m³）" prop="warehouse_volume" align="center">
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column prop="region_name" align="center">
            </el-table-column>
            <el-table-column label="冷库总数量" prop="count" align="center">
            </el-table-column>
            <el-table-column label="冷库总面积（㎡）" prop="warehouse_area" align="center">
            </el-table-column>
            <el-table-column label="冷库总容积（m³）" prop="warehouse_volume" align="center">
            </el-table-column>
          </el-table-column> </el-table
      ></el-tab-pane>
      <el-tab-pane label="第三方经营服务提供者">
        <el-table
          :data="list15"
          v-loading="listLoading5"
          fit
          border
          style="width: 100%"
          :row-class-name="getRowClass"
          @expand-change="expandChange"
        >
          <el-table-column
            label="全省食品生产经营主体及第三方冷贮服务提供者冷库信息统计表"
            align="center"
          >
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-table :data="props.row.areaData" border fit v-loading="innerListLoading">
                  <el-table-column prop="region_name" align="center">
                  </el-table-column>
                  <el-table-column label="冷库总数量" prop="count" align="center">
                  </el-table-column>
                  <el-table-column label="冷库总面积（㎡）" prop="warehouse_area" align="center">
                  </el-table-column>
                  <el-table-column label="冷库总容积（m³）" prop="warehouse_volume" align="center">
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column prop="region_name" align="center">
            </el-table-column>
            <el-table-column label="冷库总数量" prop="count" align="center">
            </el-table-column>
            <el-table-column label="冷库总面积（㎡）" prop="warehouse_area" align="center">
            </el-table-column>
            <el-table-column label="冷库总容积（m³）" prop="warehouse_volume" align="center">
            </el-table-column>
          </el-table-column> </el-table
      ></el-tab-pane>
      <el-tab-pane label="第三方检测单位">
        <el-table
                :data="list16"
                v-loading="listLoading6"
                fit
                border
                style="width: 100%"
                :row-class-name="getRowClass"
                @expand-change="expandChange"
        >
          <el-table-column
                  label="全省食品生产经营主体及第三方冷贮服务提供者冷库信息统计表"
                  align="center"
          >
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-table :data="props.row.areaData" border fit v-loading="innerListLoading">
                  <el-table-column prop="region_name" align="center">
                  </el-table-column>
                  <el-table-column label="冷库总数量" prop="count" align="center">
                  </el-table-column>
                  <el-table-column label="冷库总面积（㎡）" prop="warehouse_area" align="center">
                  </el-table-column>
                  <el-table-column label="冷库总容积（m³）" prop="warehouse_volume" align="center">
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column prop="region_name" align="center">
            </el-table-column>
            <el-table-column label="冷库总数量" prop="count" align="center">
            </el-table-column>
            <el-table-column label="冷库总面积（㎡）" prop="warehouse_area" align="center">
            </el-table-column>
            <el-table-column label="冷库总容积（m³）" prop="warehouse_volume" align="center">
            </el-table-column>
          </el-table-column> </el-table
        ></el-tab-pane>
    </el-tabs>
  </div>
</template>


<script>
import axios from "@/util/api";
export default {
  data () {
    return {
      tabIndex: "0",
      list1: [],
      list2: [],
      list3: [],
      list13: [],
      list15: [],
      list16: [],
      list7: [],
      type_id:0,
      listLoading1: false,
      listLoading2: false,
      listLoading3: false,
      listLoading4: false,
      listLoading5: false,
      listLoading6: false,
      listLoading7: false,
      innerListLoading: false,
    };
  },
  watch: {
    tabIndex (val) {
      this.initData(parseInt(val) + 1);
    },
  },
  mounted () {
    this.initData(1);
  },
  methods: {
    initData (type) {
      switch (type) {
        case 1:
          this.listLoading1 = true;
          this.type_id = 1;
          axios.get("/pc/warehouse-statistics/freezerMsgPartStatistics",{ company_type_id:  this.type_id.toString() }).then((response) => {
            let { data } = response;
            data = data.list;
            data.map((item) => {
              if (item.region_code.length > 2) {
                item.areaData = [];
              }
            });
            this.list1 = data;
            this.listLoading1 = false;
          });
          break;
        case 2:
          this.listLoading2 = true;
          this.type_id = 2;
          axios.get("/pc/warehouse-statistics/freezerMsgPartStatistics",{ company_type_id:this.type_id.toString() }).then((response) => {
            let { data } = response;
            data = data.list;
            data.map((item) => {
              if (item.region_code.length > 2) {
                item.areaData = [];
              }
            });
            this.list2 = data;
            this.listLoading2 = false;
          });
          break;
        case 3:
          this.listLoading3 = true;
          this.type_id = 3;
          axios.get("/pc/warehouse-statistics/freezerMsgPartStatistics",{ company_type_id:this.type_id.toString() }).then((response) => {
            let { data } = response;
            data = data.list;
            data.map((item) => {
              if (item.region_code.length > 2) {
                item.areaData = [];
              }
            });
            this.list3 = data;
            this.listLoading3 = false;
          });
          break;
        case 4:
          this.listLoading4 = true;
          this.type_id = 13;
          axios.get("/pc/warehouse-statistics/freezerMsgPartStatistics",{ company_type_id: this.type_id.toString()  }).then((response) => {
            let { data } = response;
            data = data.list;
            data.map((item) => {
              if (item.region_code.length > 2) {
                item.areaData = [];
              }
            });
            this.list13 = data;
            this.listLoading4 = false;
          });
          break;
        case 5:
          this.listLoading5 = true;
          this.type_id = 15;
          axios.get("/pc/warehouse-statistics/freezerMsgPartStatistics",{ company_type_id: this.type_id.toString() }).then((response) => {
            let { data } = response;
            data = data.list;
            data.map((item) => {
              if (item.region_code.length > 2) {
                item.areaData = [];
              }
            });
            this.list15 = data;
            this.listLoading5 = false;
          });
          break;
        case 6:
          this.listLoading6 = true;
          this.type_id = 16;
          axios.get("/pc/warehouse-statistics/freezerMsgPartStatistics",{ company_type_id: this.type_id.toString() }).then((response) => {
            let { data } = response;
            data = data.list;
            data.map((item) => {
              if (item.region_code.length > 2) {
                item.areaData = [];
              }
            });
            this.list16 = data;
            this.listLoading6 = false;
          });
          break;
      }
    },
    expandChange(row, expandedRows) {
      let type = this.tabIndex;
      if (expandedRows.length > 0) {
        this.innerListLoading = true;
        axios
          .get("/pc/warehouse-statistics/freezerMsgPartStatistics", {
            company_type_id: this.type_id.toString(),
            region_code: row.region_code,
          })
          .then((res) => {
            // 遍历当前页面表
            this['list' + this.type_id].forEach((temp, index) => {
              // 找到当前点击的行，把动态获取到的数据赋值进去
              if (temp.region_code === row.region_code) {
                this['list' + this.type_id][index].areaData = res.data.list;
              }
            });
            this.innerListLoading = false;
          });
      }
    },
    getRowClass (row, rowIndex) {
      let data = row.row.areaData;
      let res = [];
      if (data) {
        res.push("row-expand-has");
        return res;
      } else {
        res.push("row-expand-unhas");
        return res;
      }
    },
  },
};
</script>

<style>
.row-expand-unhas .el-table__expand-column {
  pointer-events: none;
}
.row-expand-unhas .el-table__expand-column .el-icon {
  visibility: hidden;
}
.excel .el-table__expanded-cell {
  background-color: rgb(217, 236, 255);
}
</style>